import axios from 'axios';

export const instanceIntegration = axios.create({
  baseURL: process.env.INTEGRATION_BOT_URL,
  withCredentials: false,
});

export const setHeader = (value, key = 'X-Auth-Token') => {
  instanceIntegration.defaults.headers.common[key] = value;
};

export const removeHeader = key => {
  delete instanceIntegration.defaults.headers.common[key];
};
