import { instanceIntegration } from '../api/instance/instanceIntegration';

class ZaloAPI {
  loginCallback({ code, oa_id, account_id, chatwoot_access_token }) {
    return instanceIntegration.get(
      `/zalo/loginCallback?code=${code}&oa_id=${oa_id}&account_id=${account_id}&chatwoot_access_token=${chatwoot_access_token}`
    );
  }
  updateZaloOa(id, body) {
    return instanceIntegration.put(`/zalo/${id}`, body)
  }
}

export default new ZaloAPI();
